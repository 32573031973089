import React, { useEffect } from 'react';
import { useToast } from '@chakra-ui/toast';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { logIn } from '../store/actions/authActions';
import { useDispatch } from 'react-redux';
import { getCurrentUserData } from '../util/localStorageUtils';
import { useSelector } from 'react-redux';
// import { TimeoutProvider } from '../TimeoutProvider';

//router urls
import {
  DASHBOARD_URL,
  ROOT_URL,
  LOG_IN_URL,
  ACCOUNT_VERIFICATION_URL,
  ACTIVE_MEMBERS,
  ADD_NEW_MEMBER_URL,
  NEW_REQUEST_URL,
  SETTINGS_URL,
  MEMBER_INFO_URL,
  NEW_REQUEST_VERIFICATION_URL,
  ACCOUNTS_MEMBERSHIP_URL,
  ACCOUNTS_MEMBERSHIP_PAYMENTS_NEW_URL,
  ACCOUNTS_MEMBERSHIP_MILL_URL,
  ACCOUNTS_CERTIFICATE_URL,
  ACCOUNTS_CERTIFICATE_PURCHASE_URL,
  ACCOUNTS_CERTIFICATE_PURCHASE_URL_DUTY,
  CASH_CERT_URL,
  GSP_CERT_URL,
  PROCESSING_CERT_URL,
  DUTY_CERT_URL,
  CASH_CERT_VERIFICATION_URL,
  CASH_CERT_APPLY_URL,
  CASH_CERT_DETAILS_URL,
  GSP_CERT_VERIFICATION_URL,
  GSP_CERT_APPLY_URL,
  GSP_CERT_DETAILS_URL,
  PROCESSING_CERT_VERIFICATION_URL,
  PROCESSING_CERT_APPLY_URL,
  PROCESSING_CERT_DETAILS_URL,
  DUTY_CERT_VERIFICATION_URL,
  DUTY_CERT_APPLY_URL,
  DUTY_CERT_DETAILS_URL,
  DUTY_CERT_GEN_URL,
  PROCESSING_CERT_GEN_URL,
  GSP_CERT_GEN_URL,
  CASH_CERT_GEN_URL,
  GSP_CERT_REPORT_URL,
  CASH_CERT_REPORT_URL,
  PROCESSING_CERT_REPORT_URL,
  DUTY_CERT_REPORT_URL,
  MONEY_RECEIPT_REPORT_URL,
  MILL_INFORMATION_REPORT_URL,
  LABOUR_DATABASE_URL,
  UPDATE_REQUEST_URL,
  INACTIVE_MEMBERS,
  ADD_LABOUR_URL,
  LABOUR_DETAILS_URL,
  FEE_COLLECTION_URL,
  PASSWORD_RESET_URL,
  PASSWORD_RESET_VERIFICATION_URL,
  RESET_PASSWORD_URL,
  FEE_COLLECTION_VIEW_RECEIPT,
  FEE_COLLECTION_VIEW_RECEIPT_CERTIFICATE_PURCHASE_URL_DUTY,
  MILL_REPRESENTATIVE_URL,
  FEE_COLLECTION_MILL_REPRESENTATIVE_URL,
  // UPDATE_REQUEST_VERIFICATION_URL,
} from '../constants/routerUrl';

//components
import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
import Login from '../components/auth/Login';
import AccountVerficationOTP from '../components/auth/AccountVerficationOTP';
import Dashboard from '../components/home/Dashboard';
import Settings from '../components/settings/Settings';
import MembershipNewRequest from '../components/membership/new-request/NewRequest';
import VerificationSteps from '../components/membership/new-request/StepsBar/StepsView';
import ActiveMembers from '../components/membership/members/TableView/ActiveMembers';
import InactiveMembers from '../components/membership/members/TableView/InactiveMembers';
import AddNewMember from '../components/membership/members/AddNewMember/AddNewMember';
import MemberInfo from '../components/membership/members/RegisteredMember/MemberInfo';
import AccountsMembership from '../components/accounts/membership/AccountsMembership';
import AccountsNewRequest from '../components/accounts/membership/NewRequests/NewRequest';
import MillInfoWrapper from '../components/accounts/membership/Transactions/Sections/MillInfoWrapper';
import AccountsCertificate from '../components/accounts/certificate/AccountsCertificate';
import CertRequest from '../components/accounts/certificate/CertRequest/CertRequest';
import CashIncentive from '../components/certificates/cash-incentive/CashIncentive';
import Gsp from '../components/certificates/gsp/Gsp';
import Processing from '../components/certificates/processing/Processing';
import DutyExemption from '../components/certificates/duty-exemption/DutyExemption';
import GspVer from '../components/certificates/gsp/Verification/GspVer';
import ApplyForGsp from '../components/certificates/gsp/NewApplication/ApplyForGsp';
import CashIncentiveVer from '../components/certificates/cash-incentive/Verification/CashIncentiveVer';
import CashIncentiveApplication from '../components/certificates/cash-incentive/Records/CashIncentiveApplication';
import ApplyForCashIncentive from '../components/certificates/cash-incentive/NewApplication/ApplyForCashIncentive';
import GspApplication from '../components/certificates/gsp/Records/GspApplication';
import ProcessingVer from '../components/certificates/processing/Verification/ProcessingVer';
import ApplyForProcessing from '../components/certificates/processing/NewApplication/ApplyForProcessing';
import ProcessingApplication from '../components/certificates/processing/Records/ProcessingApplication';
import DutyExemptionVer from '../components/certificates/duty-exemption/Verification/DutyExemptionVer';
import ApplyForDutyExemption from '../components/certificates/duty-exemption/NewApplication/ApplyForDutyExemption';
import DutyExemptionApplication from '../components/certificates/duty-exemption/Records/DutyExemptionApplication';
import DutyGeneration from '../components/certificates/duty-exemption/Generation';
import ProcessingGeneration from '../components/certificates/processing/Generation';
import GspGeneration from '../components/certificates/gsp/Generation';
import CashGeneration from '../components/certificates/cash-incentive/Generation';
import GspReportWizard from '../components/reports/gsp/GspReportWizard';
import CashReportWizard from '../components/reports/cash-incentive/CashReportWizard';
import ProcessingReportWizard from '../components/reports/processing/ProcessingReportWizard';
import DutyReportWizard from '../components/reports/duty-exemption/DutyReportWizard';
import MoneyReceiptReportWizard from '../components/reports/money-receipt/MoneyReceiptReportWizard';
import MillInformationReportWizard from '../components/reports/mill-information/MillInformationReportWizard';
import UpdateRequests from '../components/membership/updateRequests/UpdateRequests';
import LabourDatabase from '../components/labourDatabase/LabourDatabase';
import LabourDetails from '../components/labourDatabase/labourDetails/LabourDetails';
import AddNewData from '../components/labourDatabase/addNewData/AddNewData';
import CertRequestForDuty from '../components/accounts/certificate/CertRequest/CertRequestForDuty';
import FeeCollection from '../components/feeCollection/FeeCollection';
import PasswordResetTrigger from '../components/auth/PasswordResetTrigger';
import PasswordResetVerficationOTP from '../components/auth/PasswordResetVerificationOTP';
import ResetPassword from '../components/auth/ResetPassword';
import ViewReceipts from '../components/feeCollection/ViewReceipt';
import MillRepresentative from '../components/membership/millRepresentative/MillRepresentative';

export const history = createBrowserHistory();

const AppRouter = () => {
  const toast = useToast();
  const { notification } = useSelector((state) => state.global);

  useEffect(() => {
    const { id, title, description, status, duration, isClosable, position } = notification;
    if (title) {
      if (!toast.isActive(id)) {
        toast({
          id,
          title,
          description,
          status,
          duration,
          isClosable,
          position,
        });
      }
    }
  }, [notification]);

  const dispatch = useDispatch();
  const { user, accessToken } = getCurrentUserData();

  useEffect(() => {
    if (user && accessToken) {
      dispatch(logIn(user));
    }
  }, []);

  return (
    <Router history={history}>
      {/* <TimeoutProvider> */}
      <ScrollToTop />
      <Switch>
        {/*Public Routes*/}
        <Route exact path={ROOT_URL} render={() => <Redirect to={LOG_IN_URL} />} />
        <Route exact={true} path={LOG_IN_URL} component={Login} />
        <Route exact={true} path={ACCOUNT_VERIFICATION_URL} component={AccountVerficationOTP} />
        <Route exact={true} path={PASSWORD_RESET_URL} component={PasswordResetTrigger} />
        <Route
          exact={true}
          path={PASSWORD_RESET_VERIFICATION_URL}
          component={PasswordResetVerficationOTP}
        />
        <Route exact={true} path={RESET_PASSWORD_URL} component={ResetPassword} />

        {/*Private Routes*/}
        <PrivateRoute exact={true} path={DASHBOARD_URL} component={Dashboard} />

        {/*Membership*/}
        <PrivateRoute exact={true} path={NEW_REQUEST_URL} component={MembershipNewRequest} />
        <PrivateRoute path={`${NEW_REQUEST_VERIFICATION_URL}/:uid`} component={VerificationSteps} />
        <PrivateRoute exact={true} path={UPDATE_REQUEST_URL} component={UpdateRequests} />
        <PrivateRoute exact={true} path={ACTIVE_MEMBERS} component={ActiveMembers} />
        <PrivateRoute exact={true} path={INACTIVE_MEMBERS} component={InactiveMembers} />
        <PrivateRoute path={`${MEMBER_INFO_URL}/:uid`} component={MemberInfo} />
        <PrivateRoute exact={true} path={ADD_NEW_MEMBER_URL} component={AddNewMember} />
        <PrivateRoute exact={true} path={MILL_REPRESENTATIVE_URL} component={MillRepresentative} />

        {/*Certificates*/}
        <PrivateRoute exact={true} path={GSP_CERT_URL} component={Gsp} />
        <PrivateRoute exact={true} path={PROCESSING_CERT_URL} component={Processing} />
        <PrivateRoute exact={true} path={CASH_CERT_URL} component={CashIncentive} />
        <PrivateRoute exact={true} path={DUTY_CERT_URL} component={DutyExemption} />
        <PrivateRoute exact={true} path={GSP_CERT_VERIFICATION_URL} component={GspVer} />
        <PrivateRoute exact={true} path={GSP_CERT_DETAILS_URL} component={GspApplication} />
        <PrivateRoute exact={true} path={GSP_CERT_APPLY_URL} component={ApplyForGsp} />
        <PrivateRoute
          exact={true}
          path={PROCESSING_CERT_VERIFICATION_URL}
          component={ProcessingVer}
        />
        <PrivateRoute
          exact={true}
          path={PROCESSING_CERT_DETAILS_URL}
          component={ProcessingApplication}
        />
        <PrivateRoute
          exact={true}
          path={PROCESSING_CERT_APPLY_URL}
          component={ApplyForProcessing}
        />
        <PrivateRoute exact={true} path={CASH_CERT_VERIFICATION_URL} component={CashIncentiveVer} />
        <PrivateRoute
          exact={true}
          path={CASH_CERT_DETAILS_URL}
          component={CashIncentiveApplication}
        />
        <PrivateRoute exact={true} path={CASH_CERT_APPLY_URL} component={ApplyForCashIncentive} />
        <PrivateRoute exact={true} path={DUTY_CERT_VERIFICATION_URL} component={DutyExemptionVer} />
        <PrivateRoute
          exact={true}
          path={DUTY_CERT_DETAILS_URL}
          component={DutyExemptionApplication}
        />
        <PrivateRoute exact={true} path={DUTY_CERT_APPLY_URL} component={ApplyForDutyExemption} />

        {/*Accounts*/}
        <PrivateRoute exact={true} path={ACCOUNTS_MEMBERSHIP_URL} component={AccountsMembership} />
        <PrivateRoute
          path={`${ACCOUNTS_MEMBERSHIP_PAYMENTS_NEW_URL}/:uid`}
          component={AccountsNewRequest}
        />
        <PrivateRoute path={`${ACCOUNTS_MEMBERSHIP_MILL_URL}/:uid`} component={MillInfoWrapper} />
        <PrivateRoute
          exact={true}
          path={ACCOUNTS_CERTIFICATE_URL}
          component={AccountsCertificate}
        />
        <PrivateRoute
          exact={true}
          path={ACCOUNTS_CERTIFICATE_PURCHASE_URL}
          component={CertRequest}
        />

        <PrivateRoute
          exact={true}
          path={ACCOUNTS_CERTIFICATE_PURCHASE_URL_DUTY}
          component={CertRequestForDuty}
        />

        {/*Certificate generation*/}
        <PrivateRoute exact={true} path={GSP_CERT_GEN_URL} component={GspGeneration} />
        <PrivateRoute
          exact={true}
          path={PROCESSING_CERT_GEN_URL}
          component={ProcessingGeneration}
        />
        <PrivateRoute exact={true} path={CASH_CERT_GEN_URL} component={CashGeneration} />
        <PrivateRoute exact={true} path={DUTY_CERT_GEN_URL} component={DutyGeneration} />

        {/*Reports*/}
        <PrivateRoute exact={true} path={GSP_CERT_REPORT_URL} component={GspReportWizard} />
        <PrivateRoute
          exact={true}
          path={PROCESSING_CERT_REPORT_URL}
          component={ProcessingReportWizard}
        />
        <PrivateRoute exact={true} path={CASH_CERT_REPORT_URL} component={CashReportWizard} />
        <PrivateRoute exact={true} path={DUTY_CERT_REPORT_URL} component={DutyReportWizard} />
        <PrivateRoute
          exact={true}
          path={MONEY_RECEIPT_REPORT_URL}
          component={MoneyReceiptReportWizard}
        />
        <PrivateRoute
          exact={true}
          path={MILL_INFORMATION_REPORT_URL}
          component={MillInformationReportWizard}
        />

        {/*Labour database*/}
        <PrivateRoute exact={true} path={LABOUR_DATABASE_URL} component={LabourDatabase} />
        <PrivateRoute path={LABOUR_DETAILS_URL} component={LabourDetails} />
        <PrivateRoute exact={true} path={ADD_LABOUR_URL} component={AddNewData} />

        {/*Settings*/}
        <PrivateRoute exact={true} path={SETTINGS_URL} component={Settings} />

        {/* Fee Collection */}
        <PrivateRoute exact={true} path={FEE_COLLECTION_URL} component={FeeCollection} />
        <PrivateRoute exact={true} path={FEE_COLLECTION_VIEW_RECEIPT} component={ViewReceipts} />
        <PrivateRoute
          exact={true}
          path={FEE_COLLECTION_VIEW_RECEIPT_CERTIFICATE_PURCHASE_URL_DUTY}
          component={CertRequestForDuty}
        />
        <PrivateRoute
          exact={true}
          path={FEE_COLLECTION_MILL_REPRESENTATIVE_URL}
          component={MillRepresentative}
        />

        {/*Redirect to dashboard if no route matches*/}
        <Route render={() => <Redirect to={DASHBOARD_URL} />} />
      </Switch>
      {/* </TimeoutProvider> */}
    </Router>
  );
};

export default AppRouter;
