import React from 'react';
import RadioField from './RadioField';
import InputField from './InputField';
import SelectField from './SelectField';
import InputTextArea from './InputTextArea';
import FileInputField from './FileInputField';
import DatePickerField from './DatePickerField';
import { Text, Stack, Grid, GridItem, Checkbox } from '@chakra-ui/react';

const renderField = ({
  index,
  field,
  watch,
  errors,
  control,
  register,
  setValue,
  defaultFiles,
  datePickerWidth,
}) => {
  switch (field.fieldType) {
    case 'select':
      return (
        <SelectField
          key={index}
          control={control}
          name={field.name}
          label={field.label}
          choices={field.choices}
          isRequired={field.isRequired}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    case 'radio':
      return (
        <RadioField
          key={index}
          control={control}
          name={field.name}
          label={field.label}
          choices={field.choices}
          isRequired={field.isRequired}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    case 'date':
      return (
        <DatePickerField
          key={index}
          control={control}
          name={field.name}
          label={field.label}
          width={datePickerWidth}
          isDisabled={field.isDisabled}
          isRequired={field?.isRequired}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    case 'file':
      return (
        <FileInputField
          key={index}
          watch={watch}
          name={field.name}
          type={field.type}
          register={register}
          label={field.label}
          setValue={setValue}
          defaultFiles={defaultFiles}
          isRequired={field.isRequired}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    case 'textArea':
      return (
        <InputTextArea
          key={index}
          name={field.name}
          type={field.type}
          label={field.label}
          register={register}
          isRequired={field.isRequired}
          isInvalid={errors[field.name]}
          placeholder={field.placeholder}
          errorMessage={errors[field.name]?.message}
        />
      );
    default:
      return (
        <InputField
          key={index}
          name={field.name}
          type={field.type}
          label={field.label}
          register={register}
          isDisabled={field.isDisabled}
          isRequired={field.isRequired}
          isInvalid={errors[field.name]}
          placeholder={field.placeholder}
          errorMessage={errors[field.name]?.message}
        />
      );
  }
};

const FormSection = ({
  watch,
  errors,
  control,
  autoFill,
  register,
  setValue,
  secondary,
  formFields,
  sectionName,
  autoFillMsg,
  defaultFiles,
  sectionColumn,
  autoFillValue,
  setAutoFillValue,
}) => {
  return (
    <Stack
      spacing={2}
      borderRadius="6px"
      backgroundColor="white"
      p={secondary ? null : 3}
      pb={secondary ? 1 : null}
      px={secondary ? 3 : null}>
      <Text fontSize="1xl" fontWeight="bold">
        {sectionName}
      </Text>
      <Grid templateColumns={`repeat(${sectionColumn}, 1fr)`} gap={3}>
        {formFields.map((field, index) => (
          <GridItem
            p="1px"
            key={index}
            display="flex"
            alignItems="flex-start"
            colSpan={field.colSpan}
            overflow={field.fieldType === 'date' ? 'visible' : 'hidden'}>
            {renderField({
              index,
              field,
              watch,
              errors,
              control,
              register,
              setValue,
              defaultFiles,
              datePickerWidth: Math.ceil(window.innerWidth * 0.667) / sectionColumn,
            })}
          </GridItem>
        ))}

        {autoFill ? (
          <GridItem colSpan={sectionColumn}>
            <Checkbox isChecked={autoFillValue} onChange={setAutoFillValue} color="primary.700">
              {autoFillMsg}
            </Checkbox>
          </GridItem>
        ) : null}
      </Grid>
    </Stack>
  );
};

export default FormSection;
