import axios from './axios';

export const searchMill = async (data) => {
  try {
    const res = await axios.get('/membership/search-mill', { params: data });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteMill = async (millUid) => {
  try {
    const res = await axios.patch(`/membership/delete-mill/${millUid}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMillCategories = async () => {
  try {
    const res = await axios.get('/membership/categories');
    if (res.status === 200) return { categories: res.data.categories, error: null };
  } catch (error) {
    return { categories: null, error };
  }
};

export const getMembershipRequests = async (data) => {
  try {
    const res = await axios.get('/membership/verification', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMembershipRequest = async (data) => {
  try {
    const res = await axios.get(`/membership/verification/${data.param1}/${data.param2}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchMembershipData = async (data) => {
  if (data.rework) {
    try {
      const res = await axios.patch(`/membership/verification/${data?.param}`, data, {
        params: { rework: true },
      });
      if (res.status === 200)
        return {
          data: res.data,
          error: null,
        };
    } catch (error) {
      return { data: null, error };
    }
  } else if (data.reject) {
    try {
      const res = await axios.patch(`/membership/verification/${data?.param}`, data, {
        params: { reject: true },
      });
      if (res.status === 200)
        return {
          data: res.data,
          error: null,
        };
    } catch (error) {
      return { data: null, error };
    }
  } else if (data?.status) {
    try {
      const res = await axios.patch(
        `/membership/verification/${data?.param}`,
        { categoryId: data?.categoryId },
        {
          params: { reqStatus: data?.status },
        },
      );
      if (res.status === 200)
        return {
          data: res.data,
          error: null,
        };
    } catch (error) {
      return { data: null, error };
    }
  } else {
    try {
      const res = await axios.patch(`/membership/verification/${data?.param}`, data);
      if (res.status === 200)
        return {
          data: res.data,
          error: null,
        };
    } catch (error) {
      return { data: null, error };
    }
  }
};

export const getMembers = async (data, flag) => {
  try {
    const res = await axios.get(`/membership/members/${flag}`, { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMember = async (param) => {
  try {
    const res = await axios.get(`/membership/members/${param}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const addMember = async (data) => {
  try {
    const res = await axios.post('/membership/members/', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateExistingMember = async (data) => {
  try {
    const res = await axios.patch(`/membership/members/${data.millId}`, data.formData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const revokeMembership = async (data) => {
  try {
    const res = await axios.patch('/membership/members', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getInfoUpdateRequests = async (data) => {
  try {
    const res = await axios.get('/membership/update', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const resolveUpdateRequest = async (data) => {
  try {
    const res = await axios.patch(`/membership/update/${data?.requestId}`, {
      status: data?.status,
    });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchMembershipDocs = async (data, millId) => {
  try {
    const res = await axios.patch(`/membership/documents/upload/${millId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
