import {
  FETCH_GSP_APPLICATIONS,
  FETCH_PROCESSING_APPLICATIONS,
  FETCH_CASH_APPLICATIONS,
  FETCH_DUTY_APPLICATIONS,
  FETCH_GSP_APPLICATIONS_FOR_GEN,
  FETCH_PROCESSING_APPLICATIONS_FOR_GEN,
  FETCH_CASH_APPLICATIONS_FOR_GEN,
  FETCH_DUTY_APPLICATIONS_FOR_GEN,
  FETCH_APPLICATION_INFO,
  FETCH_REJECTED_CERTIFICATES,
} from '../actions/actionTypes';

const initialState = {
  gspApplications: null,
  processingApplications: null,
  cashApplications: null,
  dutyApplications: null,
  rejectedCertificates: null,
  gspApplicationsGen: null,
  processingApplicationsGen: null,
  cashApplicationsGen: null,
  dutyApplicationsGen: null,
  currentData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GSP_APPLICATIONS:
      return {
        ...state,
        gspApplications: action.payload,
      };
    case FETCH_PROCESSING_APPLICATIONS:
      return {
        ...state,
        processingApplications: action.payload,
      };
    case FETCH_CASH_APPLICATIONS:
      return {
        ...state,
        cashApplications: action.payload,
      };
    case FETCH_DUTY_APPLICATIONS:
      return {
        ...state,
        dutyApplications: action.payload,
      };
    case FETCH_GSP_APPLICATIONS_FOR_GEN:
      return {
        ...state,
        gspApplicationsGen: action.payload,
      };
    case FETCH_PROCESSING_APPLICATIONS_FOR_GEN:
      return {
        ...state,
        processingApplicationsGen: action.payload,
      };
    case FETCH_CASH_APPLICATIONS_FOR_GEN:
      return {
        ...state,
        cashApplicationsGen: action.payload,
      };
    case FETCH_DUTY_APPLICATIONS_FOR_GEN:
      return {
        ...state,
        dutyApplicationsGen: action.payload,
      };
    case FETCH_APPLICATION_INFO:
      return {
        ...state,
        currentData: action.payload,
      };
    case FETCH_REJECTED_CERTIFICATES:
      return {
        ...state,
        rejectedCertificates: action.payload,
      };
    default:
      return state;
  }
}
