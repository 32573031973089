import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  Text,
  Alert,
  Badge,
  VStack,
  HStack,
  Button,
  Divider,
  GridItem,
  Checkbox,
  AlertIcon,
  useDisclosure,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { MdDelete } from 'react-icons/md';
import * as commaNumber from 'comma-number';
import { useReactToPrint } from 'react-to-print';
import { useForm, useWatch } from 'react-hook-form';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineFileProtect, AiOutlineSync } from 'react-icons/ai';

//common components
import ArrearValue from './ArrearValue';
import BillDate from '../../../BillDate';
import MoneyReceipt from '../../MoneyReceipt';
import CostBreakDown from '../../CostBreakDown';
import Loader from '../../../../snippets/Loader';
import MembershipBill from '../../MembershipBill';
import InfoTiles from '../../../../common/InfoTiles';
import TransactionDetails from './TransactionDetails';
import FormButton from '../../../../common/FormButton';
import DialogueBox from '../../../../common/DialogueBox';
import DataTable from '../../../../common/dataTable/DataTable';
import MembershipYearSelection from './MembershipYearSelection';
import MembershipPeriodSelection from './MembershipPeriodSelection';
import MembershipCertPrintPreview from './MembershipCertPrintPreview';

//form sections
import MemUptoSection, { memUptoFieldsValidation } from './MemUptoSection';
import PaymentSection, { paymentFieldsValidation } from './PaymentSection';
import AmountsSection, { amountsFieldsValidation } from './AmountsSection';
import ChequeInfoSection, { chequeInfoFieldsValidation } from './ChequeInfoSection';
import MembershipSection, { membershipFieldsValidation } from './MembershipSection';
import PaymentForSection, { paymentForFieldsValidation } from './PaymentForSection';
import OtherPaymentSection, { otherPaymentFieldsValidation } from './OtherPaymentSection';
import MemPaymentTypeSection, { memPaymentTypeFieldsValidation } from './MemPaymentTypeSection';

//actions & api
import {
  deleteTransaction,
  getMembershipCost,
  payMembershipDues,
  getTransactionHistory,
  syncMembershipSubscriptionCost,
} from '../../../../../api/accounts';
import {
  FETCH_TRANSACTION_HISTORY,
  FETCH_MEMBERSHIP_REQUEST_ACC,
} from '../../../../../store/actions/actionTypes';
import { deleteMill } from '../../../../../api/membership';
import { notify } from '../../../../../store/actions/globalActions';

//utils & constants
import _ from 'lodash';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../../constants/dataTableConstants';
import { useYupValidationResolver } from '../../../../../util/yupUtils';
import { ACCOUNTS_MEMBERSHIP_URL } from '../../../../../constants/routerUrl';
import { convertNumberToWords, formatDate, renderDate } from '../../../../../util/formatUtils';
import {
  SYS_ADMIN_USER_ID,
  ACCOUNTS_HEAD_USER_ID,
  ACCOUNTS_NAHAR_USER_ID,
} from '../../../../../constants/authConstant';
import InputTextArea from '../../../../common/hookFormComponents/InputTextArea';

const MillInformation = ({ refresh }) => {
  const { uid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const membershipBillRef = useRef();
  const memMoneyReceiptRef = useRef();
  const [modal, setModal] = useState();
  const [maxVal, setMaxVal] = useState();
  const [testVal, setTestVal] = useState();
  const [rowData, setRowData] = useState();
  const membershipCertificateRef = useRef();
  const [formData, setFormData] = useState();
  const [newTrx, setNewTrx] = useState(false);
  const [cheque, setCheque] = useState(false);
  const [printYear, setPrintYear] = useState();
  const [deleteTrx, setDeleteTrx] = useState();
  const [method, setMethod] = useState('CASH');
  const [memInfo, setMemInfo] = useState(false);
  const [printPeriod, setPrintPeriod] = useState();
  const [memPayment, setMemPayment] = useState(false);
  const [extPayment, setExtPayment] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [billDate, setBillDate] = useState(new Date());
  const [transaction, setTransaction] = useState(false);
  const [moneyReceiptData, setMoneyReceiptData] = useState();
  const [otherPayment, setOtherPayment] = useState(false);
  const [donationPayment, setDonationPayment] = useState(false);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const data = useSelector((state) => state.membership.currentRequest);
  const trxData = useSelector((state) => state.accounts.transactions);
  const costData = useSelector((state) => state.accounts.currentData);
  const user = useSelector((state) => state.auth.user);

  const printMoneyReceipt = useReactToPrint({
    documentTitle: `BTMA-Money-Receipt-${data?.millName}`,
    content: () => memMoneyReceiptRef.current,
  });

  const printMembershipCertificate = useReactToPrint({
    documentTitle: `BTMA-Membership-Certificate-${data?.membershipNo}`,
    content: () => membershipCertificateRef.current,
  });

  const millInformationFields = [
    { header: 'Mill Name', value: data?.millName },
    { header: 'Category', value: data?.categoryInfo?.category },
    { header: 'Membership No', value: data?.membershipNo },
    { header: 'Membership Type', value: data?.membershipType },
    { header: 'Date of Establishment', value: renderDate(data?.dateOfEstablishment) },
    { header: 'Email', value: data?.hoInfo?.millEmail },
    { header: 'Contact', value: data?.hoInfo?.millPhoneNo },
    { header: 'Website', value: data?.website },
  ];

  const paymentSummaryFields = [
    { header: 'Expiry Date', value: renderDate(data?.membershipExpiryDate) },
    { header: 'Last Renewal', value: renderDate(data?.lastRenewalDate) },
    { header: 'Outstanding Due', value: `৳${costData?.costs?.previousDue || 0}` },
    {
      header: 'Last Paid Amount',
      value: `৳${trxData?.allTrx?.[0]?.paidAmount || 0}`,
    },
  ];

  useEffect(() => {
    (async () => {
      const res = await getMembershipCost({ uid, flag: 'RENEWAL' });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_MEMBERSHIP_REQUEST_ACC, payload: res.data });
      }
    })();
  }, []);

  const getValidationSchema = () => {
    let validations = {};

    if (transaction) {
      validations = { ...validations, ...paymentForFieldsValidation };
    }

    if (memInfo) {
      validations = { ...validations, ...membershipFieldsValidation };
    }

    if (memPayment) {
      validations = { ...validations, ...memPaymentTypeFieldsValidation };
    }

    if (newTrx) {
      validations = { ...validations, ...amountsFieldsValidation };
    }

    if (extPayment) {
      validations = { ...validations, ...memUptoFieldsValidation };
    }

    if (otherPayment || donationPayment) {
      validations = { ...validations, ...otherPaymentFieldsValidation };
    }

    if (paymentEnabled) {
      validations = { ...validations, ...paymentFieldsValidation(maxVal, testVal) };
      if (cheque) {
        validations = { ...validations, ...chequeInfoFieldsValidation(method) };
      }
    }

    return validations;
  };

  const resolver = useYupValidationResolver(yup.object().shape(getValidationSchema()));

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const upto = useWatch({ control, name: 'upto' });
  const paidAmount = useWatch({ control, name: 'paidAmount' });
  const paymentFor = useWatch({ control, name: 'paymentFor' });
  const paymentMethod = useWatch({ control, name: 'paymentMethod' });
  const currentAmount = useWatch({ control, name: 'currentAmount' });
  const membershipType = useWatch({ control, name: 'membershipType' });
  const memPaymentType = useWatch({ control, name: 'memPaymentType' });

  useEffect(() => {
    let maximum = 0;
    if (getExtensionTotal(true).yearDiff >= 1) {
      maximum = getExtensionTotal();
    } else {
      maximum += parseInt(costData?.costs?.previousDue || 0);
    }

    switch (paymentFor) {
      case 'Membership Subscription':
        setNewTrx(false);
        setMemPayment(true);
        setOtherPayment(false);
        setDonationPayment(false);
        switch (memPaymentType) {
          case 'CURRENT':
            setTestVal();
            setExtPayment(false);
            setPaymentEnabled(true);
            setMaxVal(parseInt(costData?.costs?.previousDue || 0));
            break;
          case 'EXTENSION':
            setMaxVal(maximum);
            setExtPayment(true);
            setPaymentEnabled(true);
            if (getExtensionTotal(true).yearDiff > 1) {
              setTestVal(maximum);
            } else {
              setTestVal();
            }
            break;
          default:
            setMaxVal(Infinity);
            setExtPayment(false);
            setPaymentEnabled(false);
            break;
        }
        break;
      case 'Donation':
        setTestVal();
        setNewTrx(false);
        setMaxVal(Infinity);
        setExtPayment(false);
        setMemPayment(false);
        setOtherPayment(false);
        setPaymentEnabled(true);
        setDonationPayment(true);
        break;
      case 'Other':
        setNewTrx(true);
        setExtPayment(false);
        setMemPayment(false);
        setDonationPayment(false);
        setOtherPayment(true);
        setPaymentEnabled(true);
        setMaxVal(parseInt(currentAmount || 0));
        setTestVal(parseInt(currentAmount || 0));
        break;
      default:
        setTestVal();
        setNewTrx(false);
        setMaxVal(Infinity);
        setExtPayment(false);
        setMemPayment(false);
        setOtherPayment(false);
        setDonationPayment(false);
        setPaymentEnabled(false);
        break;
    }

    setMethod(paymentMethod);

    paymentMethod === 'CASH'
      ? setCheque(false)
      : paymentMethod
      ? setCheque(true)
      : setCheque(false);
  }, [paymentFor, memPaymentType, paymentMethod, currentAmount, upto]);

  useEffect(() => {
    setValue('membershipType', data?.membershipType);
    setValue('membershipStatus', data?.membershipStatus);
    setValue('expiryYear', renderDate(data?.membershipExpiryDate).split('-')?.[2]);
  }, [data]);

  const onSubmit = (formdata) => {
    setFormData({});

    const {
      upto,
      chequeNo,
      issueDate,
      chequeDate,
      paymentFor,
      paidAmount,
      currentAmount,
      paymentMethod,
      memPaymentType,
      otherPaymentReason,
      expiryYear,
      membershipType,
      membershipStatus,
      notes,
    } = formdata;

    setModal('ALERT');
    if (transaction) {
      setFormData({
        upto,
        chequeNo,
        paymentFor,
        paidAmount,
        currentAmount,
        paymentMethod,
        memPaymentType,
        otherPaymentReason,
        updatePayment: true,
        issueDate: formatDate(issueDate),
        chequeDate: formatDate(chequeDate),
        previousDue: costData?.costs?.previousDue,
        extensionYear: getExtensionTotal(true).yearDiff,
        extensionCost: getExtensionTotal(true).extraCost,
        donation: formData?.paymentFor === 'Donation' ? true : false,
        notes,
      });
    }

    if (memInfo) {
      setFormData({
        ...formData,
        expiryYear,
        membershipType,
        membershipStatus,
        updateMembership: true,
        previousDue: costData?.costs?.previousDue,
      });
    }
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await payMembershipDues({ uid, flag: 'UPDATE', formData });
    if (res.data?.status === 200) {
      let resetObj = {};
      _.mapKeys(formData, (value, key) => (resetObj[key] = ''));
      reset(resetObj);
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
      (async () => {
        const res = await getMembershipCost({ uid, flag: 'RENEWAL' });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_MEMBERSHIP_REQUEST_ACC, payload: res.data });
        }
      })();
      (async () => {
        const res = await getTransactionHistory({
          uid,
          params: { page: 1, pageSize: 10 },
          flag: 'TRANSACTION',
        });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: res.data });
        }
      })();
    }
  };

  const handleCostSync = async () => {
    const res = await syncMembershipSubscriptionCost(uid, { cost: costData?.costs?.total });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      (async () => {
        const res = await getTransactionHistory({
          uid,
          params: { page: 1, pageSize: 10 },
          flag: 'TRANSACTION',
        });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: res.data });
        }
      })();
      refresh();
    }
  };

  const getPrintPeriod = () => {
    let months = printPeriod?.split('-');
    let years = printYear?.split('-');

    return years?.length === 2
      ? `${months?.[0]}${years[0]}-${months?.[1]}${years[1]}`
      : `${months?.[0]}${printYear}-${months?.[1]}${printYear}`;
  };

  const membershipCertData = {
    membershipNo: data?.membershipNo,
    millName: data?.millName,
    category: data?.categoryInfo?.category,
    millAddress: costData?.summary?.millAddress,
    spindles: costData?.summary?.yarnInfo?.spindlesInsCap,
    rotors: costData?.summary?.yarnInfo?.rotorsInsCap,
    shuttle: costData?.summary?.fabricInfo?.shuttleInsCap,
    shuttleLess: costData?.summary?.fabricInfo?.shuttleLessInsCap,
    knitting: costData?.summary?.fabricInfo?.knittingInsCap,
    yarnProdCap: costData?.summary?.yarnInfo?.yarnProdCap,
    fabricWovenProdCap: costData?.summary?.fabricInfo?.fabricWovenProdCap,
    fabricKnitProdCap: costData?.summary?.fabricInfo?.fabricKnitProdCap,
    texFabricWovenProcCap: costData?.summary?.textileInfo?.texFabricWovenProcCap,
    texFabricKnitProcCap: costData?.summary?.textileInfo?.texFabricKnitProcCap,
    texYarnProcCap: costData?.summary?.textileInfo?.texYarnProcCap,
    period: getPrintPeriod(),
  };

  const columns = [
    { header: 'Trx Id', accessor: 'trxId' },
    { header: 'Date', accessor: 'issueDate', isDate: true },
    { header: 'Arrears', accessor: 'previousDue', currency: '৳' },
    { header: 'Current Receivable', accessor: 'currentAmount', currency: '৳' },
    { header: 'Total Receivable', accessor: 'totalReceivable', currency: '৳' },
    { header: 'Total Received', accessor: 'paidAmount', currency: '৳' },
    { header: 'Due', accessor: 'currentDue', currency: '৳' },
    {
      header: 'Payment Method',
      accessor: 'paymentMethod',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['chequeNo as ID', 'chequeDate as Date'],
    },
    { header: 'Segment', accessor: 'period' },
    { header: 'Remarks', accessor: 'remarks', limit: 30 },
    { header: 'Notes', accessor: 'notes', limit: 30, hoverInfo: ['notes'] },
    {
      header: 'Status',
      accessor: 'paymentStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'paymentStatus',
      isButton: true,
      actionScheme:
        user?.adminRole === 'SUPERADMIN'
          ? [
              { group: 'PAID', variant: 'read', action: 'OPEN' },
              { group: 'UNPAID', variant: 'read', action: 'OPEN' },
            ]
          : [
              { group: 'PAID', variant: 'print', action: 'PRINT' },
              { group: 'UNPAID', variant: 'print', action: 'PRINT' },
            ],
    },
  ];

  const paymentStatus = {
    name: 'Payment Status',
    choices: [
      { name: 'PAID', value: 'PAID' },
      { name: 'UNPAID', value: 'UNPAID' },
    ],
  };

  const paymentOptions = {
    name: 'Payment Method',
    choices: [
      { name: 'CASH', value: 'CASH' },
      { name: 'CHEQUE', value: 'CHEQUE' },
    ],
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
      paymentMethod: selected?.['Payment Method'],
    };
    const res = await getTransactionHistory({ uid, params, flag: 'TRANSACTION' });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: res.data });
    } else {
      dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: { allTrx: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: null });
    const params = {
      endDate,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
      paymentMethod: selected?.['Payment Method'],
    };
    const res = await getTransactionHistory({ uid, params, flag: 'TRANSACTION' });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: res.data });
    } else {
      dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: { allTrx: [] } });
    }
  };

  const getBadgeColor = () => {
    return BADGE_STATE_COLORS.filter((item) => item.group === data?.membershipStatus)?.[0]?.color;
  };

  const handleReceiptOpen = ({ rowObject }) => {
    setRowData(rowObject);
    setModal('RECEIPT');
    onOpen();
  };

  const handlePrint = ({ rowObject }) => {
    setMoneyReceiptData({
      slNo: rowObject?.trxId,
      period: rowObject?.period,
      due: rowObject?.currentDue,
      total: rowObject?.paidAmount,
      mrDate: rowObject?.issueDate,
      receivedFrom: data?.millName,
      amount: rowObject?.paidAmount,
      chequeNo: rowObject?.chequeNo,
      chequeDated: rowObject?.chequeDate,
      paymentMethod: rowObject?.paymentMethod,
      paymentFor: rowObject?.remarks,
      notes: rowObject?.notes,
      paymentChoices: [
        'Membership Subscription',
        'Membership Renewal',
        'Arrear Adjustment',
        'Advertisement',
        'Installment',
      ],
    });
    setTimeout(() => {
      printMoneyReceipt();
    }, 500);
  };

  const handleBillPrint = () => {
    setModal('BILL_DATE');
    onOpen();
  };

  const handleAdjustArrear = () => {
    setModal('ARREAR_VALUE');
    onOpen();
  };

  const printMembershipBill = useReactToPrint({
    documentTitle: `BTMA-Bill-Membership-Subscription-${data?.uid}-${renderDate(billDate)}`,
    content: () => membershipBillRef.current,
  });

  const handleDeleteTrx = () => {
    const transaction = trxData?.allTrx?.find((trx) => trx.isLatest);
    setDeleteTrx(transaction);
    setModal('DELETETRX');
    onOpen();
  };

  const confirmDeleteTrx = async () => {
    const res = await deleteTransaction(deleteTrx.trxId);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
      (async () => {
        const res = await getMembershipCost({ uid, flag: 'RENEWAL' });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_MEMBERSHIP_REQUEST_ACC, payload: res.data });
        }
      })();
      (async () => {
        const res = await getTransactionHistory({
          uid,
          params: { page: 1, pageSize: 10 },
          flag: 'TRANSACTION',
        });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: res.data });
        }
      })();
    }
  };

  const handleDeleteMember = () => {
    setModal('DELETEMILL');
    onOpen();
  };

  const confirmDeleteMember = async () => {
    const res = await deleteMill(data.uid);
    if (res.data?.status === 200) {
      history.push(
        history.push({
          pathname: ACCOUNTS_MEMBERSHIP_URL,
        }),
      );
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'ALERT':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmSubmit}
            alertMessage="Are you sure you want submit these information?"
          />
        );
      case 'DELETEMILL':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="DELETE"
            mountControl={isOpen}
            performAction={confirmDeleteMember}
            alertMessage="Are you sure you want delete this mill?"
          />
        );
      case 'DELETETRX':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="DELETE"
            mountControl={isOpen}
            performAction={confirmDeleteTrx}
            alertMessage="Are you sure you want delete the following transaction?">
            <VStack overflow="auto" spacing={1} alignItems="flex-start" p={4} mt={4} bg="secondary">
              <Text fontSize="sm">
                <b>TRX Id:</b> {deleteTrx.trxId}
              </Text>
              <Text fontSize="sm">
                <b>Date:</b> {renderDate(deleteTrx.createdAt)}
              </Text>
              <Text fontSize="sm">
                <b>Arrears:</b> {deleteTrx.previousDue}
              </Text>
              <Text fontSize="sm">
                <b>Current Receivable:</b> {deleteTrx.currentAmount}
              </Text>
              <Text fontSize="sm">
                <b>Total Receivable:</b> {deleteTrx.totalReceivable}
              </Text>
              <Text fontSize="sm">
                <b>Total Received:</b> {deleteTrx.paidAmount}
              </Text>
              <Text fontSize="sm">
                <b>Due:</b> {deleteTrx.currentDue}
              </Text>
              <Text fontSize="sm">
                <b>Payment Method:</b> {deleteTrx.paymentMethod}
              </Text>
              <Text fontSize="sm">
                <b>Segment:</b> {deleteTrx.period}
              </Text>
              <Text fontSize="sm">
                <b>Remarks:</b> {deleteTrx.remarks}
              </Text>
              <Text fontSize="sm">
                <b>Status:</b> {deleteTrx.paymentStatus}
              </Text>
            </VStack>
          </DialogueBox>
        );
      case 'PERIOD':
        return (
          <DialogueBox
            close={onClose}
            mountControl={isOpen}
            actionButtonText="PRINT"
            cancelButtonText="CLOSE"
            alertTitle="Membership Period"
            performAction={() => {
              setTimeout(() => {
                printMembershipCertificate();
              }, 500);
            }}>
            <VStack>
              <MembershipPeriodSelection setPrintPeriod={setPrintPeriod} />
              <MembershipYearSelection printPeriod={printPeriod} setPrintYear={setPrintYear} />
            </VStack>
          </DialogueBox>
        );
      case 'BILL_DATE':
        return (
          <DialogueBox
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Okay"
            alertTitle="Issue Date"
            performAction={printMembershipBill}>
            <BillDate setDate={setBillDate} date={billDate} />
          </DialogueBox>
        );
      case 'ARREAR_VALUE':
        return (
          <DialogueBox
            noAction
            noCancel
            close={onClose}
            mountControl={isOpen}
            alertTitle="Adjust Arrear">
            <ArrearValue
              uid={uid}
              currentVal={data?.billInfo?.arrear}
              onClose={() => {
                onClose();
                refresh();
              }}
            />
          </DialogueBox>
        );
      case 'RECEIPT':
        return (
          <DialogueBox
            noAction
            noCancel
            variant="lg"
            close={onClose}
            mountControl={isOpen}
            alertTitle={`Trx ID: ${rowData?.trxId}`}>
            <TransactionDetails
              onClose={() => {
                onClose();
                refresh();
                (async () => {
                  const res = await getMembershipCost({ uid, flag: 'RENEWAL' });
                  if (res.data?.status === 200) {
                    dispatch({ type: FETCH_MEMBERSHIP_REQUEST_ACC, payload: res.data });
                  }
                })();
                (async () => {
                  const res = await getTransactionHistory({
                    uid,
                    params: { page: 1, pageSize: 10 },
                    flag: 'TRANSACTION',
                  });
                  if (res.data?.status === 200) {
                    dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: res.data });
                  }
                })();
              }}
              data={{ ...rowData, millName: data?.millName }}
            />
          </DialogueBox>
        );
      default:
        return null;
    }
  };

  const getExtensionTotal = (detail) => {
    let prevDue = parseInt(costData?.costs?.previousDue || 0);
    let yearDiff =
      parseInt(upto) - parseInt(renderDate(data?.membershipExpiryDate).split('-')?.[2]);
    let cost = parseInt(costData?.costs?.total || 0);

    return detail
      ? {
          result: `${cost}×${yearDiff} = ৳${cost * yearDiff}`,
          yearDiff,
          extraCost: cost * yearDiff,
        }
      : prevDue + cost * yearDiff;
  };

  const willSyncCostBtnShow = () => {
    if (data?.billInfo?.cost !== costData?.costs?.total) {
      return true;
    }
    const trx = trxData.allTrx[0];
    if (
      trx?.remarks === 'Automatic Membership Renewal' &&
      trx?.paymentStatus === 'UNPAID' &&
      new Date(trx.issueDate).toLocaleDateString() === '1/1/2024'
    ) {
      if (trx.currentDue < 15000) {
        return true;
      }
    }

    return false;
  };

  return (
    <Box p={4}>
      {data && !data?.membershipNo && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          This member is not registered with BTMA yet, please approve membership from accounts!
        </Alert>
      )}
      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        <GridItem colSpan={1} bg="white" borderRadius="4px">
          <HStack px={4} pt={4}>
            <Text fontSize="2xl" color="textSecondary">
              Membership Status:
            </Text>
            <Badge fontSize="xl" colorScheme={getBadgeColor()}>
              {data?.membershipStatus}
            </Badge>
            {data?.membershipStatus === 'PROVISIONAL MEMBER' && (
              <Text fontSize="lg" color="textSecondary">
                (till: {renderDate(data?.provisionalExpiryDate)})
              </Text>
            )}
          </HStack>
          <InfoTiles
            sectionColumn={2}
            sectionName="Mill Information"
            infoFields={millInformationFields}
          />
          <VStack px={4} alignItems="flex-end">
            <FormButton
              onClick={() => {
                setModal('PERIOD');
                onOpen();
              }}
              isDisabled={
                costData?.costs?.previousDue > 0 &&
                ![ACCOUNTS_HEAD_USER_ID, ACCOUNTS_NAHAR_USER_ID, SYS_ADMIN_USER_ID].includes(
                  user?.userId,
                )
              }
              leftIcon={<Icon as={AiOutlineFileProtect} w={7} h={7} />}>
              PRINT MEMBERSHIP CERTIFICATE
            </FormButton>
            {costData?.costs?.previousDue > 0 ? (
              <Text color="invalid" fontSize="xs" textAlign="right">
                <b>***</b>Membership Certificate can not be printed without account clearance!
              </Text>
            ) : null}
          </VStack>

          <Box display="none">
            <MembershipCertPrintPreview ref={membershipCertificateRef} data={membershipCertData} />
          </Box>
        </GridItem>
        <GridItem colSpan={1} bg="white" borderRadius="4px">
          {costData ? (
            <>
              <CostBreakDown
                transaction={data?.membershipNo}
                data={{
                  ...costData,
                  billInfo: data?.billInfo,
                  membershipType: data?.membershipType,
                  expiryYear: data?.membershipExpiryDate?.slice(0, 4),
                }}
              />
              <HStack
                pb={4}
                px={4}
                flexDir="row"
                alignItems="center"
                justifyContent="space-between">
                <HStack>
                  {willSyncCostBtnShow() && (
                    <Button
                      color="#150381"
                      variant="outline"
                      _hover={{ bg: '#CFCCE6' }}
                      rightIcon={<Icon as={AiOutlineSync} w={5} h={5} />}
                      onClick={handleCostSync}>
                      Sync Cost
                    </Button>
                  )}
                  {user?.adminRole === 'SUPERADMIN' && (
                    <Button
                      color="#150381"
                      variant="outline"
                      _hover={{ bg: '#CFCCE6' }}
                      rightIcon={<Icon as={AiOutlineSync} w={5} h={5} />}
                      onClick={handleAdjustArrear}>
                      Adjust Arrear
                    </Button>
                  )}
                </HStack>
                <FormButton onClick={handleBillPrint}>PRINT</FormButton>
                <Box display="none">
                  <MembershipBill
                    ref={membershipBillRef}
                    title="Membership Subscription"
                    data={{
                      billDate,
                      billNo: data?.uid,
                      millName: data?.millName,
                      millAddress: data?.hoInfo?.hoName,
                    }}
                    costBreakDownData={{
                      transaction: data?.membershipNo,
                      data: {
                        ...costData,
                        billInfo: data?.billInfo,
                        membershipType: data?.membershipType,
                        expiryYear: data?.membershipExpiryDate?.slice(0, 4),
                      },
                    }}
                  />
                </Box>
              </HStack>
            </>
          ) : (
            <Loader internal />
          )}
        </GridItem>
        <GridItem colSpan={2} bg="white" borderRadius="4px">
          <InfoTiles
            highlight
            sectionColumn={4}
            sectionName="Payment Summary"
            infoFields={paymentSummaryFields}
          />
        </GridItem>
      </Grid>
      <Box mt={5}>
        <DataTable
          actions={
            user?.adminRole === 'SUPERADMIN'
              ? {
                  OPEN: handleReceiptOpen,
                }
              : {
                  PRINT: handlePrint,
                }
          }
          topPanel
          keySearch
          dateRange
          isPaginated
          columns={columns}
          search={handleSearch}
          data={trxData?.allTrx}
          currentPage={trxData?.page}
          paginate={handlePagination}
          caption="Transaction History"
          entries={trxData?.totalEntries}
          pageSizes={LOGS_TABLE_DISPLAY_SIZE}
          currentPageSize={trxData?.pageSize}
          selectOptions={[paymentStatus, paymentOptions]}
        />
      </Box>
      {trxData?.allTrx?.find((trx) => trx.isLatest) && (
        <Button
          mt={4}
          bg="red.500"
          onClick={handleDeleteTrx}
          _hover={{ bg: 'red.400' }}
          _focus={{ boxShadow: 'none' }}
          rightIcon={<Icon as={MdDelete} w={5} h={5} />}>
          DELETE LATEST TRANSACTION
        </Button>
      )}
      {data?.membershipNo && (
        <Box as="form" mt={5} onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize="20px" fontWeight="bold" mb={2}>
            Update Information
          </Text>
          <Divider mb={2} />

          <Grid templateColumns="repeat(3, 1fr)" gap={5}>
            <GridItem colSpan={2} p={4} bg="white" borderRadius="4px">
              <Checkbox
                name="Payment"
                fontSize="1xl"
                fontWeight="bold"
                isChecked={transaction}
                onChange={() => setTransaction(!transaction)}>
                Payment Information
              </Checkbox>
              <Box
                bg="white"
                borderRadius="4px"
                opacity={transaction ? '1' : '0.3'}
                pointerEvents={transaction ? 'auto' : 'none'}>
                <Grid templateColumns="repeat(2,1fr)" gap={0}>
                  <GridItem>
                    <PaymentForSection register={register} control={control} errors={errors} />
                  </GridItem>

                  {memPayment ? (
                    <GridItem>
                      <MemPaymentTypeSection
                        register={register}
                        control={control}
                        errors={errors}
                      />
                    </GridItem>
                  ) : null}

                  {newTrx ? (
                    <GridItem>
                      <AmountsSection register={register} control={control} errors={errors} />
                    </GridItem>
                  ) : null}

                  {extPayment ? (
                    <GridItem>
                      <MemUptoSection
                        errors={errors}
                        control={control}
                        register={register}
                        year={renderDate(data?.membershipExpiryDate).split('-')?.[2]}
                      />
                    </GridItem>
                  ) : null}
                </Grid>

                <Divider my={2} />

                {paymentEnabled && !donationPayment ? (
                  <Box border="2px solid #6B7982" borderStyle="dotted" p={3} mb={3}>
                    <Text color="textSecondary" textAlign="center">
                      Summary
                    </Text>

                    {paymentFor !== 'Other' && memPaymentType === 'CURRENT' ? (
                      <>
                        <HStack my={2} w="100%" justifyContent="space-between">
                          <Text color="textSecondary">
                            Arrear upto (
                            {parseInt(renderDate(data?.membershipExpiryDate).split('-')?.[2]) - 1})
                          </Text>
                          <Divider flex={1} />
                          <Text>৳{commaNumber(data?.billInfo?.arrear || 0)}</Text>
                        </HStack>
                        <HStack my={2} w="100%" justifyContent="space-between">
                          <Text color="textSecondary">
                            January - June ({renderDate(data?.membershipExpiryDate).split('-')?.[2]}
                            )
                          </Text>
                          <Divider flex={1} />
                          <Text>৳{commaNumber(data?.billInfo?.['JAN-JUN'] || 0)}</Text>
                        </HStack>
                        <HStack my={2} w="100%" justifyContent="space-between">
                          <Text color="textSecondary">
                            July - December (
                            {renderDate(data?.membershipExpiryDate).split('-')?.[2]})
                          </Text>
                          <Divider flex={1} />
                          <Text>৳{commaNumber(data?.billInfo?.['JUL-DEC'] || 0)}</Text>
                        </HStack>
                        <HStack my={2} w="100%" justifyContent="space-between">
                          <Text color="textSecondary">
                            Jan - December ({renderDate(data?.membershipExpiryDate).split('-')?.[2]}
                            )
                          </Text>
                          <Divider flex={1} />
                          <Text>৳{commaNumber(data?.billInfo?.['JAN-DEC'] || 0)}</Text>
                        </HStack>
                        <Divider />
                      </>
                    ) : paymentFor !== 'Other' && memPaymentType === 'EXTENSION' ? (
                      <>
                        <HStack my={2} w="100%" justifyContent="space-between">
                          <Text color="textSecondary">
                            Arrear upto ({renderDate(data?.membershipExpiryDate).split('-')?.[2]})
                          </Text>
                          <Divider flex={1} />
                          <Text>৳{commaNumber(costData?.costs?.previousDue || 0)}</Text>
                        </HStack>
                        {getExtensionTotal(true)?.yearDiff > 1 ? (
                          <>
                            <HStack my={2} w="100%" justifyContent="space-between">
                              <Text color="textSecondary">
                                Membership Fee (for {getExtensionTotal(true)?.yearDiff} years)
                              </Text>
                              <Divider flex={1} />
                              <Text>{getExtensionTotal(true).result}</Text>
                            </HStack>
                          </>
                        ) : getExtensionTotal(true)?.yearDiff === 1 ? (
                          <>
                            <HStack my={2} w="100%" justifyContent="space-between">
                              <Text color="textSecondary">
                                January - June (
                                {parseInt(renderDate(data?.membershipExpiryDate).split('-')?.[2]) +
                                  1}
                                )
                              </Text>
                              <Divider flex={1} />
                              <Text>
                                {data?.membershipType === 'GENERAL'
                                  ? `৳${commaNumber((costData?.costs?.total || 0) / 2)}`
                                  : 0}
                              </Text>
                            </HStack>
                            <HStack my={2} w="100%" justifyContent="space-between">
                              <Text color="textSecondary">
                                July - December (
                                {parseInt(renderDate(data?.membershipExpiryDate).split('-')?.[2]) +
                                  1}
                                )
                              </Text>
                              <Divider flex={1} />
                              <Text>
                                {data?.membershipType === 'GENERAL'
                                  ? `৳${commaNumber((costData?.costs?.total || 0) / 2)}`
                                  : 0}
                              </Text>
                            </HStack>
                            <HStack my={2} w="100%" justifyContent="space-between">
                              <Text color="textSecondary">
                                Jan - December (
                                {parseInt(renderDate(data?.membershipExpiryDate).split('-')?.[2]) +
                                  1}
                                )
                              </Text>
                              <Divider flex={1} />
                              <Text>
                                {data?.membershipType === 'GENERAL'
                                  ? 0
                                  : `৳${commaNumber(costData?.costs?.total || 0)}`}
                              </Text>
                            </HStack>
                          </>
                        ) : null}
                        <Divider />
                      </>
                    ) : null}

                    <HStack my={2} w="100%" justifyContent="space-between">
                      <Text color="textSecondary">Total Payable</Text>
                      <Divider flex={1} />
                      {newTrx ? (
                        <Text>৳{commaNumber(currentAmount || 0)}</Text>
                      ) : extPayment ? (
                        <Text>৳{commaNumber(getExtensionTotal() || 0)}</Text>
                      ) : (
                        <Text>৳{commaNumber(parseInt(costData?.costs?.previousDue || 0))}</Text>
                      )}
                    </HStack>

                    <HStack my={2} w="100%" justifyContent="space-between">
                      <Text color="textSecondary">Paid Amount</Text>
                      <Divider flex={1} />
                      <Text>৳{commaNumber(paidAmount || 0)}</Text>
                    </HStack>

                    <Divider />

                    <HStack my={2} w="100%" justifyContent="space-between" mb={4}>
                      <Text color="textSecondary">Due</Text>
                      <Divider flex={1} />
                      {newTrx ? (
                        <Text>
                          ৳
                          {parseInt(currentAmount || 0) - parseInt(paidAmount || 0) > 0
                            ? commaNumber(parseInt(currentAmount || 0) - parseInt(paidAmount || 0))
                            : 0}
                        </Text>
                      ) : extPayment ? (
                        <Text>
                          ৳
                          {parseInt(getExtensionTotal() || 0) - parseInt(paidAmount || 0) > 0
                            ? commaNumber(
                                parseInt(getExtensionTotal() || 0) - parseInt(paidAmount || 0),
                              )
                            : 0}
                        </Text>
                      ) : (
                        <Text>
                          ৳
                          {parseInt(costData?.costs?.previousDue || 0) - parseInt(paidAmount || 0) >
                          0
                            ? commaNumber(
                                parseInt(costData?.costs?.previousDue || 0) -
                                  parseInt(paidAmount || 0),
                              )
                            : 0}
                        </Text>
                      )}
                    </HStack>

                    <Divider />
                    <Text color="textSecondary" mt={3}>
                      In Words
                    </Text>

                    <Text mt={2}>
                      <b>Total Payable: </b>
                      {newTrx
                        ? `${convertNumberToWords(currentAmount || 0)} Taka Only`
                        : extPayment
                        ? `${convertNumberToWords(getExtensionTotal() || 0)} Taka Only`
                        : `${convertNumberToWords(
                            parseInt(costData?.costs?.previousDue || 0),
                          )} Taka Only
                    `}
                    </Text>

                    <Text>
                      <b>Paid Amount: </b>
                      {`${convertNumberToWords(paidAmount || 0)} Taka Only`}
                    </Text>

                    <Text>
                      <b>Due: </b>
                      {newTrx
                        ? `${
                            parseInt(currentAmount || 0) - parseInt(paidAmount || 0) > 0
                              ? `${convertNumberToWords(
                                  parseInt(currentAmount || 0) - parseInt(paidAmount || 0),
                                )} Taka Only`
                              : 'N/A'
                          }`
                        : extPayment
                        ? `${
                            parseInt(getExtensionTotal() || 0) - parseInt(paidAmount || 0) > 0
                              ? `${convertNumberToWords(
                                  parseInt(getExtensionTotal() || 0) - parseInt(paidAmount || 0),
                                )} Taka Only`
                              : 'N/A'
                          }`
                        : `${
                            parseInt(costData?.costs?.previousDue || 0) -
                              parseInt(paidAmount || 0) >
                            0
                              ? `${convertNumberToWords(
                                  parseInt(costData?.costs?.previousDue || 0) -
                                    parseInt(paidAmount || 0),
                                )} Taka Only`
                              : 'N/A'
                          }`}
                    </Text>
                  </Box>
                ) : null}

                {paymentEnabled ? (
                  <PaymentSection
                    errors={errors}
                    control={control}
                    register={register}
                    type={membershipType}
                  />
                ) : null}

                {paymentEnabled && cheque ? (
                  <ChequeInfoSection
                    errors={errors}
                    control={control}
                    register={register}
                    method={paymentMethod}
                  />
                ) : null}

                {otherPayment || donationPayment ? (
                  <OtherPaymentSection register={register} control={control} errors={errors} />
                ) : null}

                {paymentEnabled && (
                  <InputTextArea
                    name="notes"
                    type="text"
                    label="Notes"
                    register={register}
                    isRequired={false}
                    isInvalid={false}
                    placeholder="Enter any extra notes/remarks you may have here"
                    // errorMessage={errors[field.name]?.message}
                  />
                )}
              </Box>
            </GridItem>

            <GridItem
              p={4}
              bg="white"
              colSpan={1}
              borderRadius="4px"
              borderStyle="dotted"
              border="2px solid #E53E3E">
              {data ? (
                <>
                  <Checkbox
                    name="Payment"
                    fontSize="1xl"
                    fontWeight="bold"
                    isChecked={memInfo}
                    onChange={() => setMemInfo(!memInfo)}>
                    Membership Information
                  </Checkbox>
                  <Box
                    bg="white"
                    borderRadius="4px"
                    opacity={memInfo ? '1' : '0.3'}
                    pointerEvents={memInfo ? 'auto' : 'none'}>
                    <MembershipSection
                      errors={errors}
                      control={control}
                      register={register}
                      year={renderDate(data?.membershipExpiryDate).split('-')?.[2]}
                    />
                  </Box>
                </>
              ) : (
                <Loader internal />
              )}
            </GridItem>
          </Grid>
          <HStack w="100%" alignItems="flex-end" justifyContent="right" mt={4}>
            {(user?.userId === ACCOUNTS_HEAD_USER_ID || user?.userId === SYS_ADMIN_USER_ID) && (
              <Button
                bg="red.500"
                onClick={handleDeleteMember}
                _hover={{ bg: 'red.400' }}
                _focus={{ boxShadow: 'none' }}
                h={['45px', '45px', '45px', '50px', '55px']}
                rightIcon={<Icon as={MdDelete} w={5} h={5} />}>
                DELETE MEMBER MILL
              </Button>
            )}
            <FormButton
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!transaction && !memInfo}>
              UPDATE
            </FormButton>
          </HStack>
          <Box display="none">
            <MoneyReceipt ref={memMoneyReceiptRef} data={moneyReceiptData} />
          </Box>
        </Box>
      )}
      {renderModal()}
    </Box>
  );
};

export default MillInformation;
