import React, { useEffect, useState } from 'react';
import { Flex, VStack, Box, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import SideNav from './SideNav';
import Header from './Header';
import './layoutStyle.css';

const Layout = ({ children }) => {
  const history = useHistory();
  const [isRestricted, setIsRestricted] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    let accountsRestrictRoutes = ['settings', 'collect-fees'];
    let adminRestrictRoutes = ['accounts', 'settings'];
    let operationRestrictRoutes = [
      'membership',
      'certificates',
      'accounts',
      'collect-fees',
      'settings',
    ];
    let feeCollectorRestrictRoutes = [
      'membership',
      'certificates',
      'accounts',
      'labour-database',
      'settings',
    ];
    let currentUrl = history.location.pathname.split('/');
    let currentRoute = currentUrl?.[1];

    if (user?.adminRole == 'ADMIN' && adminRestrictRoutes.includes(currentRoute)) {
      setIsRestricted(true);
    } else if (user?.adminRole == 'OPERATIONS' && operationRestrictRoutes.includes(currentRoute)) {
      setIsRestricted(true);
    } else if (user?.adminRole == 'ACCOUNTS' && accountsRestrictRoutes.includes(currentRoute)) {
      setIsRestricted(true);
    } else if (
      user?.adminRole == 'FEE_COLLECTOR' &&
      feeCollectorRestrictRoutes.includes(currentRoute)
    ) {
      setIsRestricted(true);
    } else {
      setIsRestricted(false);
    }
  }, [user, history.location.pathname]);

  return (
    <Flex className="app-container" w="100%" minH="100vh">
      <Box
        w="16%"
        h="100vh"
        top="0px"
        left="0px"
        bg="white"
        zIndex={100}
        overflowY="auto"
        position="sticky"
        className="side-nav-container"
        boxShadow="2px 0px 5px rgba(0,0,0,0.2)">
        <SideNav />
      </Box>
      <Box className="main" w="84%" bg="secondary">
        <Box
          w="100%"
          top="0px"
          h="6.5vh"
          bg="white"
          right="0px"
          maxH="80px"
          minH="50px"
          zIndex={99}
          position="sticky"
          id="headerWrapper"
          boxShadow="0px 2px 5px rgba(0,0,0,0.2)">
          <Header />
        </Box>
        <Box className="component-container" bg="secondary">
          {isRestricted ? (
            <Box>
              <VStack w="100%" py={20}>
                <Text color="invalid" fontSize="lg">
                  You are not authorized to access this section!
                </Text>
              </VStack>
            </Box>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default Layout;
