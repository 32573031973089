import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const NavAccordion = ({ fields }) => {
  const history = useHistory();
  const authUser = useSelector((state) => state.auth.user);

  const getPartialPath = () => {
    let urlParts = history.location.pathname.split('/');
    return `${urlParts[0]}/${urlParts[1]}/${urlParts[2]}`;
  };

  const isRestricted = (tab) => {
    let adminRestrictTabs = ['Accounts'];
    let operationRestrictTabs = ['Membership', 'Certificates', 'Accounts'];

    if (authUser?.adminRole == 'ADMIN' && adminRestrictTabs.includes(tab)) {
      return true;
    } else if (authUser?.adminRole == 'OPERATIONS' && operationRestrictTabs.includes(tab)) {
      return true;
    } else if (authUser?.adminRole == 'FEE_COLLECTOR') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Accordion allowToggle w="100%">
      {fields.map((config) => (
        <AccordionItem key={config.title}>
          {config.title && !config.menu ? (
            <AccordionItem key={config.title}>
              <Link
                to={config.url}
                style={{
                  pointerEvents:
                    authUser?.adminRole === 'FEE_COLLECTOR' && config.title === 'Labour Database'
                      ? 'none'
                      : 'auto',
                }}>
                <AccordionButton
                  p={2}
                  fontSize="md"
                  key={config.key}
                  fontWeight="bold"
                  color="primary.700"
                  _focus={{ boxShadow: 'none' }}
                  isActive={history.location.pathname === config.url}
                  opacity={
                    authUser?.adminRole === 'FEE_COLLECTOR' && config.title === 'Labour Database'
                      ? '0.3'
                      : '1'
                  }>
                  {config.title}
                </AccordionButton>
              </Link>
            </AccordionItem>
          ) : (
            <h1>
              <AccordionButton
                p={2}
                _focus={{ boxShadow: 'none' }}
                cursor={isRestricted(config.title) ? 'not-allowed' : 'pointer'}
                opacity={isRestricted(config.title) ? '0.3' : '1'}>
                <Box flex="1">
                  <Text textAlign="left" fontWeight="bold" fontSize="md" color="primary.700">
                    {config.title}
                  </Text>
                </Box>
                {isRestricted(config.title) ? null : <AccordionIcon />}
              </AccordionButton>
              {isRestricted(config.title) ? null : (
                <AccordionPanel w="100%" p={2}>
                  {config.menu &&
                    config.menu.map((instant, index) => (
                      <Link to={instant.url} key={index}>
                        <Button
                          key={instant.key}
                          p={2}
                          my={2}
                          isActive={getPartialPath() === instant.url}
                          variant="sideNavTab"
                          //onClick={() => history.push(instant.url)}
                        >
                          {instant.title}
                        </Button>
                      </Link>
                    ))}
                </AccordionPanel>
              )}
            </h1>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};
export default NavAccordion;
