import axios from './axios';

export const getAllRequests = async (data) => {
  try {
    const res = await axios.get(`/accounts/verification/${data?.flag}`, { params: data?.params });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.params?.page || 1,
          pageSize: data?.params?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMembershipCost = async (data) => {
  try {
    const res = await axios.get(`/accounts/subscription/${data?.uid}/${data?.flag}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const payMembershipDues = async (data) => {
  try {
    const res = await axios.post(
      `/accounts/subscription/${data?.uid}/${data?.renewalId}/${data?.flag}`,
      data?.formData,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMillMembershipHistory = async (data) => {
  try {
    const res = await axios.get(`/accounts/subscription/${data}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getTransactionHistory = async (data) => {
  try {
    const res = await axios.get(`/accounts/subscription/${data?.uid}/${data?.flag}`, {
      params: data?.params,
    });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.params?.page || 1,
          pageSize: data?.params?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteTransaction = async (trxId) => {
  try {
    const res = await axios.delete(`/accounts/membership/transaction/${trxId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteCertPurchaseOrder = async (type, orderId) => {
  try {
    const res = await axios.delete(`/accounts/certificate/${type}/${orderId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllProductionCertPurchases = async (data) => {
  try {
    const res = await axios.get('/accounts/production', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllProcessingCertPurchases = async (data) => {
  try {
    const res = await axios.get('/accounts/processing', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllDutyCertPurchases = async (data) => {
  try {
    const res = await axios.get('/accounts/duty-exemption', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllDutyCertPurchaseRequests = async (data) => {
  try {
    const res = await axios.get('/accounts/duty-exemption/fees', { params: data });
    if (res.status === 200)
      return {
        data: { ...res.data, page: data.page || 1, pageSize: data.pageSize || 10 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllDutyCertDues = async (millId) => {
  try {
    const res = await axios.get(`/accounts/duty-exemption/dues/${millId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getLastAssignedCertificates = async () => {
  try {
    const res = await axios.get('/accounts/certificate/allocation');
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getCertificateInvoices = async (data) => {
  try {
    const res = await axios.get(`/accounts/certificate/invoice/${data?.type}/${data?.uid}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchCertificateInvoices = async (data) => {
  try {
    const res = await axios.patch(
      `/accounts/certificate/invoice/${data?.type}/${data?.flag}/${data?.uid}`,
      data?.formData,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const postNewCertificateRequest = async (data) => {
  try {
    const res = await axios.post(`/accounts/certificate/invoice/${data?.type}`, data?.certData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return {
      data: {
        data: error?.response?.data,
      },
      error,
    };
  }
};

export const getAllPricing = async () => {
  try {
    const res = await axios.get('/shared/price-list');
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateAllPricing = async (data) => {
  try {
    const res = await axios.post('/shared/price-list', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const syncMembershipSubscriptionCost = async (uid, data) => {
  try {
    const res = await axios.post(`/accounts/sync-cost/${uid}`, data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateRenewalReqStatus = async (data) => {
  try {
    const res = await axios.patch(`/accounts/membership/renewal/${data?.uid}/${data?.status}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteCertificateBatch = async (data) => {
  try {
    const res = await axios.delete(
      `/accounts/certificate/invoice/${data?.certType}/${data?.batchId}`,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateDutyCertificateReceipt = async (uid, data) => {
  try {
    const res = await axios.patch(`/accounts/certificate/duty-receipt/${uid}`, data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const adjustArrearAmount = async (uid, data) => {
  try {
    const res = await axios.patch(`/accounts/membership/arrear-adjustment/${uid}`, data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const adjustReceiptData = async (uid, data) => {
  try {
    const res = await axios.patch(`/accounts/membership/receipt-adjustment/${uid}`, data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
