import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'itemName',
    type: 'text',
    label: 'Item Name',
  },
  {
    colSpan: 1,
    name: 'hsCode',
    type: 'text',
    label: 'H.S. Code',
  },
  {
    colSpan: 1,
    name: 'vat',
    type: 'text',
    label: 'Vat%',
  },
  {
    colSpan: 1,
    name: 'duty',
    type: 'text',
    label: 'Duty',
  },
  {
    colSpan: 1,
    name: 'importDuty',
    type: 'text',
    label: 'Import Duty',
  },
  {
    colSpan: 1,
    name: 'quantity',
    type: 'text',
    label: 'Quantity in KG',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'value',
    type: 'text',
    label: 'Invoice Value',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'currency',
    label: 'Currency',
    isRequired: true,
    choices: [
      { name: 'BDT', value: 'BDT' },
      { name: 'USD', value: 'USD' },
      { name: 'EURO', value: 'EURO' },
      { name: 'POUND', value: 'POUND' },
      { name: 'RUPEE', value: 'RUPEE' },
      { name: 'YEN', value: 'YEN' },
      { name: 'YUAN', value: 'YUAN' },
      { name: 'CHF', value: 'CHF' },
    ],
  },
  {
    colSpan: 1,
    name: 'conversionRate',
    type: 'text',
    label: 'Conversion Rate',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'total',
    type: 'text',
    label: 'Total (In Tk)',
    isRequired: true,
  },
  {
    colSpan: 2,
    fieldType: 'textArea',
    name: 'remarks',
    type: 'text',
    label: 'Remarks',
  },
];

export const requiredInfoFieldsValidation = {
  [fields[0].name]: yup.string().nullable().label(fields[0].label),
  [fields[1].name]: yup.string().nullable().label(fields[1].label),
  [fields[2].name]: yup
    .number()
    .typeError(`${fields[2].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[3].name]: yup
    .number()
    .typeError(`${fields[3].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[4].name]: yup
    .number()
    .typeError(`${fields[4].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[5].name]: yup
    .number()
    .required()
    .typeError(`${fields[5].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .label(fields[5].label),
  [fields[6].name]: yup
    .number()
    .required()
    .typeError(`${fields[6].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .label(fields[6].label),
  [fields[7].name]: yup.string().required().label(fields[7].label),
  [fields[8].name]: yup
    .number()
    .required()
    .typeError(`${fields[8].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .label(fields[8].label),
  [fields[9].name]: yup
    .number()
    .required()
    .typeError(`${fields[9].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .label(fields[9].label),
  [fields[10].name]: yup.string().nullable().label(fields[10].label),
};

const RequiredInfoSection = (props) => {
  return <FormSection sectionColumn={2} formFields={fields} {...props} />;
};

export default RequiredInfoSection;
