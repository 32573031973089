import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import NavPanel from '../../common/NavPanel';
//import DutyCertTable from './TableView/DutyCertTable';
import ProductionCertTable from './TableView/ProductionCertTable';
import ProcessingCertTable from './TableView/ProcessingCertTable';
import NewCertificateForm from './NewCertificate/NewCertificateForm';
import { useHistory, useLocation } from 'react-router-dom';
import DutyExemptionTemplate from './DutyExemptionTemplate/DutyExemptionTemplate';

const AccountsCertificate = () => {
  const [selected, setSelected] = useState(0);
  const [scroll, setScroll] = useState(false);
  const navList = ['Blank Certificate', 'Production', 'Processing', 'Duty Exemption'];

  useEffect(() => {
    document.title = 'BTMA - Accounts';
  }, []);

  const handleScroll = () => {
    window.scrollY > 10 ? setScroll(true) : setScroll(false);
  };

  window.addEventListener('scroll', handleScroll);

  const history = useHistory();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const tabValue = queryParams.get('tab');

  // useEffect(() => {
  //   let currState = parseInt(localStorage.getItem('ACC_CERT_TAB'));
  //   if (currState) {
  //     setSelected(currState);
  //   }
  //   return () => {
  //     setSelected(0);
  //   };
  // }, []);

  // const updateTab = (tabState) => {
  //   setSelected(tabState);
  //   localStorage.setItem('ACC_CERT_TAB', tabState);
  // };

  useEffect(() => {
    if (!tabValue) {
      setSelected(0);
    } else {
      setSelected(parseInt(tabValue));
    }
  }, [tabValue]);

  const updateTab = (tabState) => {
    history.push({
      search: `?tab=${tabState}`,
    });
  };

  const renderContent = () => {
    switch (selected) {
      case 0:
        return <NewCertificateForm />;
      case 1:
        return <ProductionCertTable />;
      case 2:
        return <ProcessingCertTable />;
      case 3:
        // return <DutyCertTable />;
        return <DutyExemptionTemplate />;
      default:
        return <NewCertificateForm />;
    }
  };

  return (
    <Box bg="secondary">
      <Box
        p={4}
        right="0px"
        zIndex={98}
        bg="secondary"
        position="sticky"
        shadow={scroll ? 'sm' : 'none'}
        top={`${document?.getElementById('headerWrapper')?.offsetHeight}px`}>
        <NavPanel navState={selected} setNavState={updateTab} navList={navList} />
      </Box>
      <Box p={4}>{renderContent()}</Box>
    </Box>
  );
};

export default AccountsCertificate;
